import { GetServerSideProps } from "next";
import { handleRequestError } from "~/utils/rollbar";
import { defaultMediaFields, findStoreById } from "~/strapi/api";
import { StrapiProduct, StrapiResponse, StrapiStore } from "~/shared-types";
import { SectionHeading } from "~/components/SectionHeading";
import { Breadcrumbs } from "~/components/Breadcrumbs";
import { getRoute } from "~/utils/getRoute";
import {
  GetProductsOptions,
  getProducts,
  useProductsQuery,
} from "~/api/products";
import { TagsMasonry } from "~/components/TagsMasonry";
import { useEffect } from "react";
import Link from "next/link";
import { useSingleStoreAtom } from "~/atoms/singleStore";
import { useRouter } from "next/router";
import { HTMLLevels } from "~/components/Heading";
import { FeaturedMedia } from "./FeaturedMedia";
import { StoreAddress } from "~/pages/products/[productId]/StoreAddress";
import { StoreContact } from "~/pages/products/[productId]/StoreContact";
import { StoreDescription } from "~/pages/products/[productId]/StoreDescription";
import { EditStoreButton } from "~/components/Backroom/EditStoreButton";
import classNames from "classnames";
import { StoreSocialLinks } from "~/components/StoreSocialLinks";
import { useWithFetchAll } from "~/api/useWithFetchAll";
import { StoreInfoCard } from "../../StoreInfoCard";

export type ServerSideProps = {
  store: StrapiStore | null;
  productsPage: StrapiResponse<StrapiProduct[]>;
  productsOptions: GetProductsOptions;
};

type Props = ServerSideProps & {
  isBackroom?: boolean;
};

export const getProductsOptions = (storeId: string | number) => ({
  populate: [
    "images",
    "subCategories",
    "subCategories.mainCategory",
    "mainCategory",
    "productTags",
  ],
  filters: { store: { id: { $eqi: storeId } } },
  sortKey: "order",
  sortDirection: "asc" as const,
});

export const getServerSideProps: GetServerSideProps<
  ServerSideProps,
  { id: string }
> = async (context) => {
  const { id } = context.params ?? {};

  if (!id) return { notFound: true };

  const productsPage = await getProducts(getProductsOptions(id));

  const store = await findStoreById(id, {
    populate: {
      featuredMedia: { fields: [...(defaultMediaFields || []), "mime"] },
      coverVideo: { fields: [...(defaultMediaFields || []), "mime"] },
      storeImage: { fields: defaultMediaFields },
    },
  }).catch(handleRequestError(context.req, null));

  if (!store) return { notFound: true };

  return {
    props: { store, productsPage, productsOptions: getProductsOptions(id) },
  };
};

const StorePage = ({
  productsOptions,
  store,
  productsPage,
  isBackroom,
}: Props) => {
  if (!store) return null;

  const { name, featuredMedia, coverVideo } = store.attributes;
  const { setSingleStore, onVisitDifferentStore } = useSingleStoreAtom();
  const { query } = useRouter();

  const { data, fetchAllPages } = useWithFetchAll(
    useProductsQuery(productsOptions, productsPage)
  );

  const products = data.pages?.flatMap((page) => page?.data) as StrapiProduct[];

  // Effect: Fetch all pages immediately
  useEffect(() => {
    fetchAllPages();
  }, []);

  // Effect: Set single store if query param is present
  useEffect(() => {
    if (!query.ssv) return onVisitDifferentStore(store.id);

    setSingleStore({ currentStoreId: store.id, limitedStoreId: store.id });
  }, [query.ssv]);

  return (
    <div className="mb-8 md:mb-0">
      {!isBackroom && (
        <Breadcrumbs
          links={[
            { href: getRoute("/stores"), label: "Butikker" },
            { label: name },
          ]}
        />
      )}

      <section className="mt-8 sm:mt-14">
        <div
          className={classNames(
            isBackroom && "flex flex-col md:flex-row md:justify-between"
          )}
        >
          <SectionHeading>
            <SectionHeading.Title level={HTMLLevels.h1}>
              {name}
            </SectionHeading.Title>
          </SectionHeading>
          {isBackroom && (
            <EditStoreButton className="mb-6 order-[-1] md:order-1" />
          )}
        </div>

        <FeaturedMedia
          className="mt-4 sm:mt-8"
          media={coverVideo?.data ?? featuredMedia?.data}
        />

        <div className="flex flex-col gap-20 lg:gap-10 lg:flex-row lg:pr-10">
          <StoreDescription className="pt-8 pb-5" store={store} />
          <StoreInfoCard
            className="relative -mt-10 mx-auto lg:mr-0 hidden lg:block"
            store={store}
          />
        </div>
      </section>

      {products && products.length > 0 && (
        <div className="bg-white mt-4 pt-8 px-[var(--page-x)] md:px-[var(--page-x-md)] -mx-[var(--page-x)] md:-mx-[var(--page-x-md)]">
          <SectionHeading>
            <SectionHeading.Title className="mb-8">
              Produkter
            </SectionHeading.Title>
            <SectionHeading.Subtitle className="mb-10 sm:mb-20">
              Produkter fra{" "}
              <Link
                href={getRoute("/stores/:id", {
                  id: store.id,
                })}
                className="text-primary"
              >
                {name}
              </Link>
            </SectionHeading.Subtitle>
          </SectionHeading>
          <TagsMasonry products={products} />
        </div>
      )}

      <StoreInfoCard
        className="relative mx-auto mt-10 -mb-3 md:-mb-10 lg:mr-0 lg:hidden"
        store={store}
      />
    </div>
  );
};

export default StorePage;
